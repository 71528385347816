import React from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaInstagram } from "react-icons/fa";
import "../styles/navbar.css";
import "../styles/site.css";

function NavBar() {
  return (
    <Navbar expand="lg" className="navbar-dark bg-transparent navfont">
      <Container fluid className="d-flex justify-content-between navfont">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto nav-padding">
            <NavLink
              exact
              to="/"
              activeClassName="active"
              className="nav-link navbox"
            >
              Home
            </NavLink>
            <NavLink
              to="/films"
              activeClassName="active"
              className="nav-link navbox"
            >
              Films
            </NavLink>
            <NavLink
              to="/adverts"
              activeClassName="active"
              className="nav-link navbox"
            >
              Adverts
            </NavLink>
            <NavLink
              to="/contact"
              activeClassName="active"
              className="nav-link navbox"
            >
              Contact
            </NavLink>
          </Nav>
          <Nav className="me-auto nav-padding">
            <Nav.Link
              href="https://www.instagram.com/lagopesado"
              target="_blank"
              className="nav-link navbox"
            >
              <FaInstagram size="1.5em" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;