import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/home.css";
import "../styles/site.css";
import "../styles/films.css";

function Adverts() {
  return (
    <Container>
      <Row>
        <Col className="center">
          <br />
          <br />
          <div className="video-container filmbox">
          <a href="https://miamifilmfestival2024.eventive.org/films/65e00a0bff80d00049344ed5">
            Miami Film Festival
            <br />
            
              Lowest budget feature to premiere and compete at the Miami Film Festival
            </a>
          </div>
          <br />
          <br />
          <div className="video-container filmbox">
          <a href="https://www.miamiherald.com/news/local/article287151750.html">
            Miami Herald
            <br />
            
              "‘They’ve got something to say.’ Local filmmakers make their mark
              at Miami Film Festival"
            </a>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col className="center">
          <div className="video-container filmbox">
          <a href="https://www.wlrn.org/arts-culture/2024-04-05/miami-film-festival-2024-south-florida-stories">
            WLRN.com
            <br />
            
              "At the Miami Film Festival, these South Florida stories make it
              onto the big screen"
            </a>
          </div>
        </Col>
      </Row>

      <br />
      <br />

      <Row>
        <Col className="center">
          <div className="video-container filmbox">
          <a href="https://www.axios.com/local/miami/2024/04/04/film-festival-begins-screenings-showtimes-what-to-see">
            Axios.com
            <br />
            
              "Lights, camera, action: Miami Film Festival begins"
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Adverts;
