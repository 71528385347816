import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import selfImage from "../assets/DSCF3341.JPG";

import "../styles/site.css";
import "../styles/home.css";

function Home() {
  return (
    <Container>
      <Row>
      
        <Col md={6}>
          <h1 className="name box d-flex justify-content-center">Daniel Lago</h1>
          <br />
          <p className="font box">
            - Hialeah's hardest working, self-indulgent filmmaker since 2007.
            <br />
            <br />
            - DIY: Director, Writer, Producer, Cinematographer, Actor, Entire Post-Production (including film score) on "A Communist Brainwashed My Daughter", which competed at 41st Miami Film Festival's Made In MIA category.
            <br />
            <br />
            - Former Local 600 Camera Assistant (Burn Notice, Graceland, The Glades). 
            <br />
            <br />
            - Satires, comedies, and PSAs.
            <br />
            <br />
            - Favorite topics: local ecological advocacy, animal welfare, Cuban
            machismo, Miami fraud, and comedy. <br />
          </p>
        </Col>
        
        <br/>
        <br/>

        <Col md={12} lg={6} className="text-center mb-4 mb-lg-0 pic">
          <img
            className="imgbox"
            src={selfImage}
            alt="Daniel Lago looking through camera viewfinder"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Col>
      
      </Row>
    </Container>
  );
}

export default Home;
