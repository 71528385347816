import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/films.css";


function Films() {
  return (
    <Container>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div className="video-container filmbox">
            <h1>
              <i>A Communist Brainwashed My Daughter</i> (2024)
            </h1>
            <div className="embed-responsive">
              <iframe
                width="460"
                height="315"
                src="https://www.youtube.com/embed/Ct0kEveHC1I?si=Frft8e8AMY9dicHT"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <p>A satire about Cuban machismo, middle-school drama, and environmental messaging through connected vignettes: a frustrated Miami science teacher forces apathetic students to pick up litter in detention, the drama those students actually care about, and an overworked Cuban father who challenges the teacher.

A DIY feature film made for under $6000, under two years, with over two dozen cast members, and with a three-person skeleton crew. Not a single day went over five hours. The director/writer/producer/actor also served as cinematographer (and gaffer/key grip), the entire art department, and the entire post-production department.

Mel Brooks meets Pedro Almodovar, written by Greta Thunberg and a telenovela writer's room. *Premiered At Miami Film Festival 2024 in Made In MIA Category*</p>
          </div>

          <div className="video-container filmbox">
            <h1>
              <i>Everything Has Led To This Moment</i> (2020-2022)
            </h1>
            <div className="embed-responsive">
              <iframe
                width="460"
                height="315"
                src="https://www.youtube.com/embed/cWG9BFvjxoA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <p>
              I produced and was the cinematographer for this film directed and
              written by Manny Delgadillo. We shot this over the span of two
              years, through the pandemic (with appropriate safety measures); a
              covid-free set! It's a 90s period-piece about two sisters,
              domestic hardship, and grief. Please follow the filmmaker:{" "}
              <a href="https://www.instagram.com/manny.delgadillo/">
                @manny.delgadillo
              </a>
            </p>
          </div>

          <div className="video-container filmbox">
            <h1>
              <i>Presence, Parenting, and Play</i> (2021)
            </h1>
            <div className="embed-responsive">
              <iframe
                width="460"
                height="315"
                src="https://www.youtube.com/embed/AdcGOCCQmXU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <p>
              <i>Presence, Parenting, and Play</i> is a mockumentary about
              counseling an oppositional kid with his oppositional Cuban dad and
              orbiting grandfather. It was shot on an old Angineux zoom lens, so
              there's a lot of zooming.
            </p>
          </div>

          <div className="video-container filmbox">
            <h1>
              <i>Your Pet? Your Child</i> (2020)
            </h1>
            <div className="embed-responsive">
              <iframe
                width="460"
                height="315"
                src="https://www.youtube.com/embed/RfRZk7H-tRg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <p>
              <i>Your Pet? Your Child</i> is a PSA about animal abandonment. It
              offended a professor I look up to. I hope it doesn't offend anyone
              else.
            </p>
          </div>

          <div className="video-container filmbox">
            <h1>
              <i>Tropical Disturbance</i> (2019)
            </h1>
            <div className="embed-responsive">
              <iframe
                width="460"
                height="315"
                src="https://www.youtube.com/embed/HY4kIUiLohg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <p>
              <i>Tropical Disturbance</i> Tropical Disturbance is a
              longish-short film I made with my family in 2019 and remixed again
              in 2021. 95% of it was shot with a telephoto macro lens. It's
              goofy and angsty. Synopsis: "In 2017, Hurricane Irma looms over
              the Cuban capital of the U.S.: Hialeah, Florida. Nereyda wants to
              prepare, but her senile husband, Jose, tends to wander off. There
              is more work to do than this family can handle in a story of
              domestic inequality between two Cuban exiles and their veteran
              son. A triangle of unaddressed conflicts and unresolved history
              unravels in the suburbs of Hialeah, full of reckless motorists and
              an existential storm; Irma is coming. What will be left?"
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Films;
