import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/home.css";
import selfImage from "../assets/self2.jpg";

function Contact() {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "50vh" }}
    >
      <Row className="align-items-center">
      <br />
        <br />
      <div className="d-flex justify-content-center">
      <Col md={12} lg={6} className="box">
          
          <p className="mb-0 d-flex justify-content-center">Email: <a href="mailto:WHFC305@gmail.com">WHFC305@gmail.com</a></p>
          
          
        </Col>
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
        <Col md={12} lg={6} className="box">
        
          <p className="mb-0 d-flex justify-content-center"><a href="https://filmfreeway.com/DanielLago">Film Freeway</a></p>
        </Col>
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
        <Col md={12} lg={6} className="text-center mb-4 mb-lg-0">
          <img
            className="imgbox"
            src={selfImage}
            alt="Daniel Lago looking through camera viewfinder"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Col>
        </div>
        
      </Row>
    </Container>
  );
}

export default Contact;